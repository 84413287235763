<!--
 * @Description: 主页头部
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-12 14:52:29
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-06 15:39:07
-->
<template>
  <div class="layout">
     <Header
      :isShowPersonal="true"
      :isShowHelp="true"
    />
    <div class="other">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { ref, toRefs, reactive, watch, watchEffect } from 'vue'
import Header from "@/components/common/QuestionHeader";

// 根据路由显示头部信息
const onRouterChangeEffect = () => {
  const route = useRoute()
  const data = reactive({
    isShowLogo: false,
    isShowCenter: false
  })
  watchEffect(() => {
    const showLogo = /(personal|recycleBin)$/g.test(route.fullPath)
    const showCenter = /design/g.test(route.fullPath)
    data.isShowLogo = showLogo
    data.isShowCenter = showCenter
  })
  const { isShowLogo, isShowCenter } = toRefs(data)
  return { isShowLogo, isShowCenter }
  
}

export default({
  name: 'Layout',
  components: {
    Header,
  },
  setup() {
    const router = useRouter()
    const handlePersonalClick = () => { router.push('/personal') }
    const { isShowLogo, isShowCenter } = onRouterChangeEffect()
    return {
      isShowLogo,
      isShowCenter,
      handlePersonalClick,
    }
  },
})
</script>

<style lang="less" scoped>
.layout {
  width: 100vw;
  height: 100vh;
  background-color: @backgroundColor;
  .other {
    width: 100%;
    height: calc(100vh - 56px);
    margin-top: 56px;
    background-color: @backgroundColor;
    overflow-y: scroll;
  }
}
</style>
